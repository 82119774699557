<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Comp Enrolment Summary</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Comp Enrolment
                </div>
              </div>
            </div>
          </div>
          <div class="card-body" style="min-height: 100vh; color: antiquewhite">
            <div class="row">
              <v-col cols="12" sm="4"  v-if="candidates.length>0" v-for="candidate in candidates">
                <v-card >
                  <div class="form-group row">
                    <label class="col-xl-12 col-lg-12 col-form-label">Candidate  information
                    </label>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Full name:</label>
                    <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{candidate.full_name}}
                    </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Enrolment key:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_key}}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Enrolment pin:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_pin}}</span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Exam name:</label>
                    <div class="col-lg-8 col-xl-6">
                      <span class="font-weight-bold">{{candidate.exam_name ? candidate.exam_name : 'NA' }}
                    </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-xl-4 col-lg-4 col-form-label text-right">Date of birth:</label>
                    <div class="col-lg-8 col-xl-6">
                    <span class="font-weight-bold">{{candidate.formatted_date_of_birth}}
                    </span>
                    </div>
                  </div>

                </v-card>
              </v-col>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CandidateService from "@/services/candidate/CandidateService";
const candidate=new CandidateService();
export default {
  data(){
    return{
      candidate_ids:[],
      candidates:[],
    }
  },
  methods:{
    getCandidateByIds(){
      candidate
          .getByIds({'candidate_ids':this.candidate_ids})
          .then((response) => {
              this.candidates=response.data.data;
          })
          .catch((err) => {

          }).finally(() => {

      });
    },
  },
  mounted() {
    if(typeof this.$route.query.q=='string'){
      this.candidate_ids.push(this.$route.query.q);
    }else{
      this.candidate_ids=this.$route.query.q;
    }

    this.getCandidateByIds();
  }
}
</script>